/** @jsx jsx */
import { jsx } from '@emotion/core'
import { navigate } from 'gatsby'

import { BackBtn } from './styles';

import ArrowSVG from '../../../../images/icons/left-arrow.svg';

const BackBtnComponent = () => {
    
    const backActions = () => {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        if (url.split('/').filter((path) => !!path).pop() === 'campanha-natal-nestle') {
            sessionStorage.removeItem('@denario_app/christmas_campaign/logged')
            sessionStorage.removeItem('@denario_app/christmas_campaign/special_campaign')
            sessionStorage.removeItem('@denario_app/christmas_campaign/comite_id')
            navigate(0)
        } else {
            navigate(-1)
        }
    }

    return (
        <BackBtn onClick={backActions}>
            <ArrowSVG />
        </BackBtn>
    )
}

export default BackBtnComponent