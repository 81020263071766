import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const BackBtn = styled.button`
    position: fixed;
    width: 50px;
    left: 10px;
    top: 10px;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
    z-index: 2;

    svg {
        path {
            fill: white;
        }
    }
`