import api from './api'

export async function fetchAllCampaigns() {
  return api.get(`/campaigns`, {
    params: {
      size: 9999,
    },
  })
}

export async function queryCampaigns(searchParams) {
  return api.get(`/campaigns`, {
    params: {
      page: 1,
      size: 4,
      searchParams,
    },
  })
}

export async function getCampaign(id) {
  return api.get(`/campaigns/${id}`)
}
export async function getChildCampaignValue(id) {
  return api.get(`/campaigns/child/${id}/donation-values`)
}

export async function getCommittees() {
  return api.get(`/campaigns/committee`)
}

export async function getCommittee({ id, accessCode }) {
  return api.get(`/campaigns/committee/${id}`, {
    params: {
      accessCode,
    },
  })
}

export async function getChild({ comiteId }) {
  return api.get(`/campaigns/child`, {
    params: {
      committee: comiteId,
      drawAgain: false,
    },
  })
}
