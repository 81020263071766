export const currencyType = [
  {
    value: 'BRL',
    label: 'BRL (R$)',
    symbol: 'R$',
  },
  {
    value: 'USD',
    label: 'USD (US$)',
    symbol: 'US$',
  },
  {
    value: 'EUR',
    label: 'EUR (€)',
    symbol: '€',
  },
]

export const currencies = {
  BRL: {
    locale: 'pt-BR',
    type: 'BRL',
  },
  USD: {
    locale: 'en-US',
    type: 'USD',
  },
  EUR: {
    locale: 'en-GR',
    type: 'EUR',
  },
}

export const formatMoney = currency =>
  new Intl.NumberFormat(currency.locale, {
    style: 'currency',
    currency: currency.type,
  }).format
