/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

// import '../../config/ReactotronConfig'
import { store, persistor } from '../../store'

import theme from './theme'
import fontStyles from './font-styles'
import resetStyles from './reset-styles'

import background from '../../images/campanha-nestle/background.jpg'

const Container = styled.div`
  background-image: url(${background});
  background-position: center top;
  background-size: cover;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
`

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Container>
              <Global styles={css(resetStyles, fontStyles)} />
              <ToastContainer autoClose={3000} />
              {children}
            </Container>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
