/** @jsx jsx */
import { jsx } from '@emotion/core'

import PropTypes from 'prop-types'
import { Redirect } from '@reach/router'

import ShuffleLetter from '../../../components/pages/CampanhaNatalNestle/Campaigns/CompartilheAlegria/ShuffleLetter'

const Page = ({ location }) => {
  if (!location.state) {
    return <Redirect to="/pt/campanha-natal-nestle" noThrow />
  }

  const { campaignId, comiteId } = location.state

  return <ShuffleLetter campaignId={campaignId} comiteId={comiteId} />
}

Page.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      campaignId: PropTypes.number,
      comiteId: PropTypes.number,
    }),
  }).isRequired,
}

export default Page
