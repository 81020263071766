/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useStaticQuery, graphql, navigate } from 'gatsby'

import { motion, useAnimation } from 'framer-motion'

import { toast } from 'react-toastify'
import { getChild } from '../../../../../../services/campaigns'

import Layout from '../../../../../Layout/christmas'
import BackBtnComponent from '../../../BackBtn'

import { normalizeGoogleDriveImageURL } from '../../../../../../utils/normalizeGoogleDriveImageURL'

import { formatMoney, currencies } from '../../../../../../utils/money'

import {
  Container,
  Header,
  HeaderImage,
  Main,
  Footer,
  FooterLogo,
  FooterPage,
  FooterImages,
  LetterContent,
  LetterInfo,
  Commands,
} from './styles'

const Component = ({ campaignId, comiteId }) => {
  const data = useStaticQuery(graphql`
    query {
      voluntariar: file(
        relativePath: { eq: "campanha-nestle/logo_voluntariar.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 478) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nestle: file(relativePath: { eq: "campanha-nestle/logo-nestle.png" }) {
        childImageSharp {
          fluid(maxWidth: 159) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nestlePage: file(
        relativePath: { eq: "campanha-nestle/logo-nestle-page.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 264) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      letter: file(relativePath: { eq: "campanha-nestle/letter.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const controls = useAnimation()

  const boxVariants = {
    hidden: { scale: 0, rotateZ: 0 },
    visible: { scale: 1, rotateZ: 360 },
  }

  const [kid, setKid] = useState({})

  function scrollToLetter() {
    if (typeof window !== 'undefined') {
      const $letter = document.querySelector('.letter-component')

      window.scrollTo({
        top: $letter.offsetTop - 20,
        behavior: 'smooth',
      })
    }
  }

  async function shuffleKid() {
    try {
      await controls.start('hidden')
      scrollToLetter()

      const response = await getChild({ comiteId })
      const {
        id,
        name,
        age,
        photo,
        location,
        gift,
        organization,
        donationValue,
      } = response.data

      const avatarURL = normalizeGoogleDriveImageURL(photo)

      setKid({
        id,
        name,
        age,
        avatarURL,
        location,
        gift,
        organization,
        donationValue,
      })

      await controls.start('visible')
    } catch (error) {
      toast.warn(error)
    }
  }

  function handleSelectKid() {
    sessionStorage.setItem('@denario_app/christmas_campaign/fixed-value', 70)

    sessionStorage.setItem(
      '@denario_app/christmas_campaign',
      JSON.stringify({ christmas_campaign: true, kid })
    )

    navigate(`/pt/campanhas/doar/${campaignId}/`, {
      state: { christmas_campaign: true, kid },
    })
  }

  useEffect(() => {
    shuffleKid()
  }, [])

  return (
    <Layout>
      <Container>
        <Header>
          <HeaderImage
            loading="eager"
            fluid={data.voluntariar.childImageSharp.fluid}
          />
        </Header>

        <BackBtnComponent/>

        <Main className="letter-component">
          <motion.article
            variants={boxVariants}
            initial="hidden"
            animate={controls}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <LetterContent>
              <main>
                <h2>Caro Papai Noel,</h2>

                <p>
                  Me comportei muito bem esse ano, e o que eu mais queria nesse
                  Natal era um(a) {kid.gift}.
                </p>

                <p>
                  Beijos!
                  <br />E um ótimo Natal para você!
                </p>
              </main>

              <picture>
                <img src={kid.avatarURL} alt={kid.name} />
              </picture>

              <div>
                {kid.name}, {kid.age} anos.
                <br />
                {kid.location} {kid.organization && ` | ${kid.organization}`}
              </div>
            </LetterContent>
          </motion.article>

          <LetterInfo>
            <strong>
              Esse presente custa{' '}
              {formatMoney(currencies.BRL)(kid.donationValue)}
            </strong>

            <p>
              As crianças presenteadas são de ONGs parceiras da Fundação Nestlé,
              através dos comitês regionais. Elas escreveram cartinhas para o
              Papai Noel contando qual presente gostariam de ganhar.
            </p>
            <p>
              Você pode sortear quantas vezes quiser, e escolher a criança e o
              presente que quer ou pode contribuir, basta clicar em
              &quot;SORTEAR OUTRA CRIANÇA&quot; aqui embaixo.
            </p>
            <p>
              Contribua e ajude uma criança a ter um final de ano cheio de
              alegria.
            </p>
          </LetterInfo>

          <Commands>
            <button type="button" onClick={handleSelectKid}>
              Doar para essa criança
            </button>
            <button type="button" onClick={shuffleKid}>
              Sortear outra criança
            </button>
          </Commands>
        </Main>

        <Footer>
          <FooterImages>
            <FooterLogo
              loading="eager"
              fluid={data.nestle.childImageSharp.fluid}
            />

            <FooterPage
              loading="eager"
              fluid={data.nestlePage.childImageSharp.fluid}
            />
          </FooterImages>
        </Footer>
      </Container>
    </Layout>
  )
}

Component.propTypes = {
  campaignId: PropTypes.number.isRequired,
  comiteId: PropTypes.number.isRequired,
}

export default Component
