import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Img from 'gatsby-image'

import letter from '../../../../../../images/campanha-nestle/letter.png'
import letterMobile from '../../../../../../images/campanha-nestle/letter-mobile.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'MontSerrat', Arial, Helvetica, sans-serif;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
`

export const Header = styled.header`
  display: block;
  width: 100%;
`

export const HeaderImage = styled(Img)`
  width: 400px;
  max-width: 85vw;
  margin: 0 auto;
  height: auto;

  img {
    display: block;
    margin-left: 6px;
  }
`

export const Main = styled.main`
  ${({ theme }) => css`
    margin: auto;
    width: 1120px;

    ${theme.queries.small} {
      padding: 50px 0;
      width: 90vw;

      article {
        background-image: url(${letterMobile});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        height: 700px;
        width: 90vw;
        padding: 0 30px 0;
      }
    }

    ${theme.queries.large} {
      article {
        background-image: url(${letter});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        height: 800px;
        padding: 300px 80px 0;
      }
    }
  `}
`

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    position: relative;
    justify-content: flex-start;
    z-index: 0;

    padding: 50px 20px;

    ${theme.queries.large} {
      justify-content: flex-end;
      padding: 50px 180px;
    }
  `}
`

export const FooterLogo = styled(Img)`
  display: block;

  width: 156px;
  height: auto;
`

export const FooterPage = styled(Img)`
  width: 220px;
  height: auto;
  position: absolute !important;
  bottom: 0;
  right: 0;
  display: block;
`

export const FooterImages = styled.div`
  display: block;
  margin-left: auto;
`

export const LetterContent = styled.div`
  ${({ theme }) => css`
    font-family: 'Bradley', Courier New;

    display: flex;
    position: relative;

    main {
      flex: 1;

      h2 {
        color: #b52025;
        font-size: 42px;
        line-height: 44px;
        margin-bottom: 30px;
      }

      p {
        color: #0e2528;
        font-size: 30px;
        line-height: 30px;
        max-width: 360px;

        & + p {
          margin-top: 50px;
        }
      }
    }

    picture {
      position: absolute;
      top: -150px;
      right: 20px;
      width: 175px;
      height: 235px;

      img {
        display: block;

        width: 100%;
        height: 100%;
      }
    }

    div {
      margin-top: 120px;
      font-size: 30px;
      line-height: 46px;
      width: 340px;
    }

    ${theme.queries.small} {
      flex-direction: column-reverse;
      height: 100%;

      main {
        padding: 10px 0 20px;
        margin-top: 80px;

        h2,
        p {
          font-size: 16px;
          color: #000;

          & + p {
            margin-top: 10px;
          }
        }

        h2 {
          margin-bottom: 0;
        }
      }

      picture {
        top: 35px;
        right: 25px;
        width: 100px;
        height: 145px;
      }

      div {
        font-size: 18px;
        line-height: 32px;
        margin-top: 220px;
        margin-left: 20%;
        width: 230px;
        height: 110px;
      }
    }
  `}
`

export const LetterInfo = styled.div`
  ${({ theme }) => css`
    color: #fff;
    font-family: 'MontSerrat', sans-serif;

    strong {
      display: block;
      margin-bottom: 30px;
      font-weight: normal;

      font-size: 24px;
      text-transform: uppercase;
    }

    ${theme.queries.small} {
      strong {
        font-size: 20px;
        margin-top: 50px;
      }
    }

    p {
      text-transform: none;
      margin-bottom: 15px;
      line-height: 20px;
      font-size: 16px;
    }
  `}
`

export const Commands = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin: 30px 0;
    position: relative;

    z-index: 1;

    button {
      border: 1px solid #fff;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      padding: 25px 40px;
      transition: all 200ms ease-in-out;

      &:hover {
        background: #fff;
        color: black;
      }
    }

    ${theme.queries.small} {
      margin-top: 50px;
      flex-direction: column-reverse;

      button {
        padding: 20px 8px;
        width: 100%;

        margin-bottom: 20px;
      }
    }
  `}
`
